import Typed from 'typed.js';

document.querySelector("#typed").innerHTML = "";

var typed3 = new Typed('#typed', {
  strings: ['deliver', 'import', 'distribute', 'consult on'],
  typeSpeed: 100,
  backSpeed: 60,
  smartBackspace: true,
  loop: true
});
